import { Helmet } from 'react-helmet';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Frontend/Component/Meta/Component */
export class Meta extends SourceMeta {
    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <Helmet>
                <title>
                    { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
                </title>
            </Helmet>
        );
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <Helmet key={ name || property }>
                            { name && <meta name={ name } content={ content } /> }
                            { property && <meta property={ property } content={ content } /> }
                        </Helmet>
                    );
                }) }
            </>
        );
    }
}

export default Meta;

/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const STORE_FINDER_HEADER_TAG = 'store-finder-our-stores';
export const STORE_DETAILS_HEADER_TAG = 'store-finder-details';
export const POSITION = 'position';
export const STORE_FINDER_PAGE_URL = '/stores';
export const STORE_FINDER_MEDIA_PATH = '/media/store_finder/stores/';
export const STORE_FINDER_PAGE_TITLE = __('Shops');
export const STORE_FINDER_BREADCRUMB = {
    url: STORE_FINDER_PAGE_URL,
    name: STORE_FINDER_PAGE_TITLE
};

// vvv map config
// the perfect size is 25 x 41, but we can change it later.
export const MARKER_ICON_WIDTH = 25;
export const MARKER_ICON_HEIGHT = 41;
export const MARKER_ICON_CLASS_NAME = 'leaflet-div-icon';
export const MAP_PADDING = 27;
export const MAP_POPUP_PADDING = 50;
export const MAP_POPUP_OFFSET_X = 180;
export const MAP_POPUP_OFFSET_Y = 150;
export const MAP_POPUP_OFFSET_MOBILE_X = 0;
export const MAP_POPUP_OFFSET_MOBILE_Y = 100;
export const DETAILS_MAP_ZOOM_LEVEL = 18;
export const DETAILS_MAP_ANIMATION_DURATION = 0.8;

// vvv labels
export const LABEL_ALL_CITIES = __('All Cities');
export const LABEL_ALL_STORES = __('All Stores');
export const LABEL_OUR_SHOPS = __('Our Shops');
export const LABEL_HEADER_TITLE = __('Our Stores');
export const LABEL_WORKING_HOURS = __('Working Hours');
export const LABEL_WORKING_DAYS = __('Working Days');
export const LABEL_ADDRESS = __('Address');
export const LABEL_EMAIL = __('Email');
export const LABEL_PHONE = __('Phone');
export const LABEL_DESCRIPTION = __('Store Information');
export const LABEL_SHOW_ON_THE_MAP = __('Show on the map');
export const LABEL_SHOW_MORE_INFO = __('Enter');

export const META_OBJECT_NAME = LABEL_OUR_SHOPS;
export const META_OBJECT_TITLE = LABEL_OUR_SHOPS;
export const META_OBJECT_META_TITLE = LABEL_OUR_SHOPS;
export const META_OBJECT_META_DESCRIPTION = __('Our Shops - Find the closest store');
export const META_OBJECT_META_KEYWORD = 'stores';

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import ReturnsCollapse from './ReturnsCollapse.component';

/** @namespace Forel/AmastyRma/Component/ReturnsCollapse/Container */
export class ReturnsCollapseContainer extends PureComponent {
    containerFunctions = {
        toggleCollapse: this.toggleCollapse.bind(this)
    };

    containerProps() {
        const {
            isCollapsed
        } = this.state;

        return {
            isCollapsed
        };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isCollapsed: false
        };
    }

    toggleCollapse() {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }

    render() {
        return (
            <ReturnsCollapse
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ReturnsCollapseContainer;

/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_ADDRESS_INFO = 'UPDATE_ADDRESS_INFO';

/** @namespace Scandiweb/Stripe/Store/FailedAddressInformation/Action/updateAddressInfo */
export const updateAddressInfo = (addressInfo) => ({
    type: UPDATE_ADDRESS_INFO,
    addressInfo
});

/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Stripe/Query/RestoreCart/Mutation */
export class RestoreCartMutation {
    getRestoreCartMutation({ cartId, failedOrderId }) {
        return new Field('restoreCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('failedOrderId', 'String!', failedOrderId);
    }
}

export default new RestoreCartMutation();

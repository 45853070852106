/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    OptionsType, OrderItemSettingsType, OrderItemType, PlaceholderOptionType
} from '../../type/AmastyRMA.type';
import {
    OPTION_TYPE_CONDITION,
    OPTION_TYPE_REASON, OPTION_TYPE_RESOLUTION
} from '../AmastyReturnOption/AmastyReturnOption.config';
import AmastyOrderItem from './AmastyOrderItem.component';

/** @namespace Forel/AmastyRma/Component/AmastyOrderItem/Container */
export class AmastyOrderItemContainer extends PureComponent {
    static propTypes = {
        index: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        item: OrderItemType.isRequired,
        options: PropTypes.objectOf(PropTypes.oneOfType([
            PlaceholderOptionType.isRequired,
            OptionsType.isRequired
        ])).isRequired,
        onChange: PropTypes.func.isRequired,
        settings: OrderItemSettingsType.isRequired,
        onReturnOptionChange: PropTypes.func.isRequired
    };

    containerFunctions = {
        changeCheckboxState: this.changeCheckboxState.bind(this),
        changeItemState: this.changeItemState.bind(this)
    };

    componentDidMount() {
        this.loadState();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoading: true,
            checkboxesTriggers: {},
            returnOptions: [],
            keyForSelect: []
        };
    }

    loadState() {
        const {
            index,
            options: {
                reasons,
                conditions,
                resolutions
            }
        } = this.props;

        this.setState({
            returnOptions: [
                {
                    index,
                    optionType: OPTION_TYPE_REASON,
                    options: reasons
                },
                {
                    index,
                    optionType: OPTION_TYPE_CONDITION,
                    options: conditions
                },
                {
                    index,
                    optionType: OPTION_TYPE_RESOLUTION,
                    options: resolutions
                }
            ],
            isLoading: false
        });
    }

    changeItemState(e) {
        const {
            onChange,
            item,
            item: {
                product_name
            }
        } = this.props;

        const newArray = item.amrma_order_item.map((item) => item.order_item_id);

        const { order_item_id } = item.amrma_order_item[0];

        const finalOrderItem = item.amrma_order_item.length <= 1 ? order_item_id : newArray;

        const keyForSelect = Array.isArray(finalOrderItem) ? finalOrderItem[0] : finalOrderItem;

        if (e.target.checked) {
            this.setState({ keyForSelect });
        } else {
            this.setState({ keyForSelect: ['deselezionato'] });
        }

        onChange({
            e,
            finalOrderItem,
            product_name
        });

        this.changeCheckboxState(e);
    }

    changeCheckboxState(e) {
        const { checkboxesTriggers } = this.state;
        const { id, checked } = e.target;

        this.setState({
            checkboxesTriggers: {
                ...checkboxesTriggers,
                [id]: checked
            }
        });
    }

    containerProps() {
        const {
            index,
            id,
            item,
            options,
            settings,
            onChange,
            onReturnOptionChange
        } = this.props;

        const {
            isLoading,
            checkboxesTriggers,
            returnOptions,
            keyForSelect
        } = this.state;

        return {
            isLoading,
            checkboxesTriggers,
            returnOptions,
            index,
            id,
            item,
            options,
            settings,
            onChange,
            onReturnOptionChange,
            keyForSelect
        };
    }

    render() {
        return (
            <AmastyOrderItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyOrderItemContainer;

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const REQUEST_INCREMENT_ID_LENGTH = 9;

export const STATUS_PROCESSING = 0;
export const STATUS_APPROVED = 1;
export const STATUS_DELIVERED = 2;
export const STATUS_COMPLETED = 3;
export const STATUS_CANCELED_OR_REJECTED = 4;

export const LOGIN_URL = '/customer/account/login';

export const CLASS_CHECKED = '-checked';
export const CLASS_COMPLETE = '-complete';
export const CLASS_DISABLED = 'disabled';
export const CLASS_FAILED = '-failed';

export const ATTRIBUTE_ELEMENT_RATING = 'data-element-rating';

export const ID_POPUP_INFO = 'amrma-popup';

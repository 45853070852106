import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Frontend/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    getAddProductToCartMutation(cartId, cartItems, pickup) {
        return new Field('addProductsToCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartItemInput!]!', cartItems)
            .addField(this._getUserErrorsField())
            .addField(this._getCartField(pickup));
    }

    _getCartField(pickup) {
        return new Field('cart').addFieldList(['id']).addField(this._getItemsField(pickup));
    }

    _getItemsField(pickup) {
        return new Field('items').addFieldList(['id']).addField(this._getPickupField(pickup));
    }

    _getPickupField(pickup) {
        return new Field('pickup')
            .addArgument('pickup', 'CartItemPickupInput', pickup)
            .addFieldList(['pickup_location_code', 'is_express_pickup']);
    }

    _getPickupFieldQuery() {
        return new Field('pickup')
            .addFieldList(['pickup_location_code', 'is_express_pickup']);
    }

    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            this._getPickupFieldQuery(),
            this._getCustomizableOptionsFields(),
            this._getDownloadableLinksField(),
            this._getBundleOptionsField(),
            this._getProductField()
        ];
    }
}

export default new CartQuery();

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { OptionsType, StringOrNumberType } from '../../type/AmastyRMA.type';

import './AmastyReturnOption.style';

/** @namespace Forel/AmastyRma/Component/AmastyReturnOption/Component */
export class AmastyReturnOptionComponent extends PureComponent {
    static propTypes = {
        defaultValue: StringOrNumberType.isRequired,
        options: OptionsType.isRequired,
        changeOptionState: PropTypes.func.isRequired,
        keyForSelect: PropTypes.oneOfType([
            PropTypes.arrayOf,
            PropTypes.number
        ]).isRequired
    };

    render() {
        const {
            defaultValue,
            options,
            changeOptionState,
            keyForSelect
        } = this.props;

        const finalKey = `key-${keyForSelect}`;

        return (
            <div block="AmastyReturnOption">
                <Field
                  key={ finalKey }
                  type={ FIELD_TYPE.select }
                  attr={ {
                      defaultValue,
                      noPlaceholder: true,
                      className: 'amrma-select amrma-return-option'
                  } }
                  options={ options }
                  events={ {
                      onChange: changeOptionState
                  } }
                />
            </div>
        );
    }
}

export default AmastyReturnOptionComponent;

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Forel/AmastyRma/Query/AmastyMyReturns/Query */
export class AmastyMyReturnsQuery {
    getSettingsField() {
        return new Field('getAmRmaSettings')
            .addField('isChatEnabled');
    }

    getOrderListField() {
        return new Field('getAmRmaOrderList')
            .addFieldList(this.getOrderListFields());
    }

    getReturnsForCustomerField() {
        return new Field('getAmRmaReturnsForCurrentCustomer')
            .addFieldList(this.getReturnsForCustomerFields());
    }

    getCustomerOrdersField(orderIncrementIdSet) {
        return new Field('customer')
            .addField(this.getOrdersField(Array.from(orderIncrementIdSet)));
    }

    getProductsField(skuSet) {
        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', { sku: { in: Array.from(skuSet) } })
            .addField(this.getProductsItemsField());
    }

    getProductsItemsField() {
        return new Field('items')
            .addFieldList(this.getProductsItemsFields());
    }

    getSmallImageField() {
        return new Field('small_image')
            .addField('url');
    }

    getOrdersField(orderIncrementIdArray) {
        return new Field('orders')
            .addArgument('filter', 'CustomerOrdersFilterInput', { number: { in: orderIncrementIdArray } })
            .addField(this.getOrdersItemsField());
    }

    getOrdersItemsField() {
        return new Field('items')
            .addFieldList(this.getOrdersItemsFields());
    }

    getOrderItemsField() {
        return new Field('items')
            .addField('product_sku');
    }

    getRequestItemsField() {
        return new Field('request_items')
            .addFieldList(this.getRequestItemsFields());
    }

    getStatusField() {
        return new Field('status')
            .addFieldList(this.getStatusFields());
    }

    getReturnsForCustomerFields() {
        return [
            'request_id',
            'created_at',
            'modified_at',
            'order_increment_id',
            this.getRequestItemsField(),
            this.getStatusField()
        ];
    }

    getProductsItemsFields() {
        return [
            'sku',
            this.getSmallImageField()
        ];
    }

    getOrdersItemsFields() {
        return [
            'increment_id',
            this.getOrderItemsField()
        ];
    }

    getRequestItemsFields() {
        return [
            'qty',
            'product_url_key',
            'product_name'
        ];
    }

    getOrderListFields() {
        return [
            'order_id',
            'label'
        ];
    }

    getStatusFields() {
        return [
            'color',
            'title'
        ];
    }
}

export default new AmastyMyReturnsQuery();

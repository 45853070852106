/* Override Sidea */

import {
    DISPLAY_MODE_BOTH as SOURCE_DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK as SOURCE_DISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_PRODUCTS as SOURCE_DISPLAY_MODE_PRODUCTS,
    GRID_LAYOUT as SOURCE_GRID_LAYOUT,
    LAYOUT_KEY as SOURCE_LAYOUT_KEY,
    LIST_LAYOUT as SOURCE_LIST_LAYOUT,
    LOADING_TIME as SOURCE_LOADING_TIME,
    SORT_DIRECTION_TYPE as SOURCE_SORT_DIRECTION_TYPE
} from 'SourceRoute/CategoryPage/CategoryPage.config';

export const LOADING_TIME = SOURCE_LOADING_TIME;

export const DISPLAY_MODE_PRODUCTS = SOURCE_DISPLAY_MODE_PRODUCTS;

export const DISPLAY_MODE_CMS_BLOCK = SOURCE_DISPLAY_MODE_CMS_BLOCK;

export const DISPLAY_MODE_BOTH = SOURCE_DISPLAY_MODE_BOTH;

export const LAYOUT_KEY = SOURCE_LAYOUT_KEY;

export const GRID_LAYOUT = SOURCE_GRID_LAYOUT;

export const LIST_LAYOUT = SOURCE_LIST_LAYOUT;

export const SORT_DIRECTION_TYPE = SOURCE_SORT_DIRECTION_TYPE;

/* costanti per rilevare se la categoria è tipo Brand o Marchi */
export const locationBrands = 'brands';
export const locationBrand = 'brand';
export const locationMarchi = 'marchi';
export const locationAnteprima = 'anteprima';

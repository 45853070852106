/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const ID_POPUP_INFO = 'amrma-popup';

export const ATTRIBUTE_FILE_HASH = 'data-file-hash';
export const ATTRIBUTE_ORDER_ID = 'data-order-id';
export const ATTRIBUTE_KEY = 'data-key';

export const LOGIN_URL = '/customer/account/login';

export const PLACEHOLDER_VALUE = -1;

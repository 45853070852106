/** override Sidea */

import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/AddIcon/AddIcon.component';

/** @namespace Frontend/Component/AddIcon/Component */
export class AddIcon extends SourceAddIcon {
    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="AddIcon"
              mods={ { isPrimary } }
              width="12"
              height="12"
              viewBox="0 0 12 12"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g transform="translate(0 5.392)">
                    <rect width="12" height="1.216" />
                </g>
                <g transform="translate(5.392)">
                    <rect width="1.216" height="12" />
                </g>
            </svg>
        );
    }
}

export default AddIcon;

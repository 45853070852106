/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import Link from 'Component/Link';

import {
    OptionsType, OrderItemSettingsType, OrderItemType, PlaceholderOptionType, ReturnOptionsType
} from '../../type/AmastyRMA.type';
import { getQtyOptions } from '../../util/Utils';
import AmastyReturnOption from '../AmastyReturnOption';
import {
    OPTION_TYPE_QTY
} from '../AmastyReturnOption/AmastyReturnOption.config';
import { URL_PREFIX } from '../MyReturnsTab/MyReturnsTab.config';
import {
    INCREMENT_ID_LENGTH, NO_RETURNABLE_REASON_ALREADY_RETURNED,
    NO_RETURNABLE_REASON_EXPIRED_PERIOD,
    NO_RETURNABLE_REASON_ITEM_WAS_ON_SALE,
    NO_RETURNABLE_REASON_ITEM_WASNT_SHIPPED,
    NO_RETURNABLE_REASON_REFUNDED
} from './AmastyOrderItem.config';

import './AmastyOrderItem.style';

/** @namespace Forel/AmastyRma/Component/AmastyOrderItem/Component */
export class AmastyOrderItemComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        item: OrderItemType.isRequired,
        changeItemState: PropTypes.func.isRequired,
        onReturnOptionChange: PropTypes.func.isRequired,
        checkboxesTriggers: PropTypes.objectOf(PropTypes.bool).isRequired,
        options: PropTypes.objectOf(PropTypes.oneOfType([
            PlaceholderOptionType.isRequired,
            OptionsType.isRequired
        ])).isRequired,
        settings: OrderItemSettingsType.isRequired,
        returnOptions: ReturnOptionsType.isRequired,
        keyForSelect: PropTypes.number.isRequired
    };

    noReturnableRenderMap = {
        [NO_RETURNABLE_REASON_ITEM_WASNT_SHIPPED]: {
            render: () => this.renderItemWasntShippedBlock()
        },
        [NO_RETURNABLE_REASON_REFUNDED]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __('This product is already refunded.')
            })
        },
        [NO_RETURNABLE_REASON_ALREADY_RETURNED]: {
            render: () => this.renderAlreadyReturnedBlock()
        },
        [NO_RETURNABLE_REASON_EXPIRED_PERIOD]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __("The return for this product can't be processed."),
                warning: __('The return period expired.')
            })
        },
        [NO_RETURNABLE_REASON_ITEM_WAS_ON_SALE]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __('This product cannot be returned.'),
                warning: __('This product was on sale.')
            })
        }
    };

    renderContent() {
        return (
            <div className="amrma-product-item">
                <div className="amrma-product">
                    { this.renderCheckbox() }
                    { this.renderProductInformation() }
                </div>
                { this.renderReturnOptionsBlock() }
            </div>
        );
    }

    renderCheckbox() {
        const {
            id,
            item,
            changeItemState
        } = this.props;

        const { is_returnable } = item.amrma_order_item[0];

        return (
            <div className="amrma-checkbox-container">
                <div block="AmastyOrderItem" elem="Checkbox">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id,
                          className: 'amrma-checkbox amrma-return-item',
                          disabled: !is_returnable
                      } }
                      events={ {
                          onChange: changeItemState
                      } }
                    />
                </div>
            </div>
        );
    }

    renderBrand() {
        const {
            item: {
                configurable_item
            }
        } = this.props;

        const brand = configurable_item[0].manufacturer;

        return (
            <h3 block="MyAccountOrderItemsTableRow" elem="Brand">{ brand }</h3>
        );
    }

    renderImage() {
        const {
            item: {
                product_name,
                configurable_item
            }
        } = this.props;

        if (configurable_item[0].media_gallery.length < 1) {
            return null;
        }

        const imageUrl = configurable_item[0].media_gallery[0].url;

        return (
            <Image
              src={ imageUrl }
              mix={ {
                  block: 'MyAccountOrderItemsTableRow',
                  elem: 'Picture'
              } }
              ratio="square"
              alt={ `Product ${product_name} thumbnail.` }
            />
        );
    }

    renderProductInformation() {
        const {
            item,
            item: {
                product_name
            }
        } = this.props;

        const amrmaOrderItem = item.amrma_order_item[0];

        return (
            <div className="amrma-product-information">
                <div>
                    { this.renderImage() }
                </div>
                <div className="information-block">
                    <p className="product-title">{ product_name }</p>
                    { this.renderBrand() }
                    { this.renderOrderOptionsBlock(item, amrmaOrderItem) }
                    { this.renderEnteredOptionsBlock(item, amrmaOrderItem) }
                </div>
            </div>
        );
    }

    renderOrderOptionsBlock({ selected_options }) {
        if (!selected_options?.length) {
            return null;
        }

        return (
            <div>
                { selected_options.map(this.renderOrderOptionsElements) }
            </div>
        );
    }

    renderEnteredOptionsBlock({ entered_options }) {
        if (!entered_options?.length) {
            return null;
        }

        return (
            <div>
                { entered_options.map(this.renderOrderEnteredElements) }
            </div>
        );
    }

    renderOrderOptionsElements = ({ label, value }) => (
        <p className="amrma-info">
            { __(`${label}: `) }
            { value }
        </p>
    );

    renderOrderEnteredElements = ({ label, items }) => (
        <p className="amrma-info">
            { __(`${label}: `) }
            { items[0].title }
        </p>
    );

    renderReturnOptionsBlock() {
        const {
            id: triggerId,
            item,
            returnOptions,
            checkboxesTriggers
        } = this.props;

        const { is_returnable, order_item_id, no_returnable_reason } = item.amrma_order_item[0];

        if (!is_returnable) {
            const { render: renderNoReturnableBlock } = this.noReturnableRenderMap[no_returnable_reason];
            return renderNoReturnableBlock();
        }

        return (
            <div
              className="amrma-product-message amrma-return-settings-container"
              hidden={ !checkboxesTriggers[triggerId] }
            >
                <div className="amrma-message-container -options">
                    <div className="amrma-return-qty">
                        <label
                          htmlFor={ `amrma-qty-select-${order_item_id}` }
                          className="amrma-label"
                        >
                            { __('Return Qty') }
                        </label>
                        { this.renderQtySelectBlock() }
                    </div>
                    { returnOptions.map(this.renderReturnOption) }
                    <p className="text-right">{ __('Required fields*') }</p>
                </div>
            </div>
        );
    }

    renderReturnOption = ({ index, optionType, options }) => {
        const {
            item: {
                product_name,
                amrma_order_item
            },
            options: {
                placeholder
            },
            onReturnOptionChange,
            keyForSelect
        } = this.props;

        /* eslint-disable-next-line fp/no-let */
        let title;

        if (optionType === 'reason') {
            title = __('Reasons for return*');
        } else if (optionType === 'condition') {
            title = __('Product conditions*');
        } else if (optionType === 'resolution') {
            title = __('Refund method*');
        } else {
            title = __('Reasons for return*');
        }

        return (
            <div className="amrma-return-select">
                <h4 className="amrma-title">{ title }</h4>
                <AmastyReturnOption
                  defaultValue={ placeholder.value }
                  productName={ product_name }
                  optionType={ optionType }
                  orderItemIndex={ index }
                  options={ options }
                  onChange={ onReturnOptionChange }
                  amrmaOrderItem={ amrma_order_item }
                  keyForSelect={ keyForSelect }
                />
            </div>
        );
    };

    renderItemWasntShippedBlock() {
        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { __("This product wasn't shipped.") }
                    </p>
                </div>
            </div>
        );
    }

    renderAlreadyReturnedBlock() {
        const {
            item
        } = this.props;

        const { already_returned_requests } = item.amrma_order_item[0];

        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { __('Rma request for this product is already created.') }
                        <br />
                        { __(' Existing Return(s):') }
                    </p>
                    <div className="amrma-returns-container">
                        { already_returned_requests.map(this.renderExistingReturnLink) }
                    </div>
                </div>
            </div>
        );
    }

    renderExistingReturnLink = (returnRequestId) => (
        <p className="_nomargin amrma-return-link">
            <Link
              key={ returnRequestId }
              to={ `/${URL_PREFIX}/account/view/request/${returnRequestId}` }
              target="_blank"
              rel="noreferrer"
            >
                { `#${returnRequestId.toString().padStart(INCREMENT_ID_LENGTH, '0')}` }
            </Link>
        </p>
    );

    renderGenericNoReturnableBlock(
        {
            cause,
            warning
        }
    ) {
        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { cause }
                    </p>
                    <p className="amrma-warning-message _nomargin">
                        { warning }
                    </p>
                    { __('If you have questions, please contact the store administrator: ') }
                    { this.renderAdministratorPhoneNumberBlock() }
                    { this.renderAdministratorEmailBlock() }
                </div>
            </div>
        );
    }

    renderAdministratorPhoneNumberBlock() {
        const {
            settings: {
                isShowAdministratorContact,
                administratorPhoneNumber
            }
        } = this.props;

        if (!isShowAdministratorContact || !administratorPhoneNumber) {
            return null;
        }

        return (
            <p className="amrma-phone _nomargin">
                { /* eslint-disable-next-line react/forbid-elements */ }
                <a href={ `tel:${administratorPhoneNumber}` }>{ administratorPhoneNumber }</a>
            </p>
        );
    }

    renderAdministratorEmailBlock() {
        const {
            settings: {
                isShowAdministratorContact,
                administratorEmail
            }
        } = this.props;

        if (!isShowAdministratorContact || !administratorEmail) {
            return null;
        }

        return (
            <p className="amrma-adminmail _nomargin">
                <Link to={ `mailto:${administratorEmail}` }>{ administratorEmail }</Link>
            </p>
        );
    }

    renderQtySelectBlock() {
        const {
            index: i,
            item,
            item: {
                amrma_order_item
            },
            onReturnOptionChange,
            keyForSelect
        } = this.props;

        const { purchased_qty, available_qty } = item.amrma_order_item[0];

        if (!Number.isInteger(purchased_qty)) {
            return (
                <input
                  className="amrma-item-qty"
                />
            );
        }

        return (
            <div className="amrma-qty-select">
                <AmastyReturnOption
                  defaultValue={ getQtyOptions(available_qty)[0].value }
                  optionType={ OPTION_TYPE_QTY }
                  orderItemIndex={ i }
                  options={ getQtyOptions(available_qty) }
                  onChange={ onReturnOptionChange }
                  amrmaOrderItem={ amrma_order_item }
                  keyForSelect={ keyForSelect }
                />
                <span className="amrma-total">
                    /
                    { ' ' }
                    { available_qty }
                </span>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div block="AmastyOrderItem">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyOrderItemComponent;

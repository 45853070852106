/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { PureComponent } from 'react';

import { MatchType } from 'Type/Router.type';

import AmastyMyReturnsPage from '../../route/AmastyMyReturnsPage';
import AmastyNewReturn from '../../route/AmastyNewReturn';
import AmastyViewReturn from '../../route/AmastyViewReturn';
import { URL_PREFIX } from './MyReturnsTab.config';

/** @namespace Forel/AmastyRma/Component/MyReturnsTab/Component */
export class MyReturnsTabComponent extends PureComponent {
    static propTypes = {
        match: MatchType.isRequired
    };

    render() {
        const {
            match: {
                url: route
            }
        } = this.props;

        if (route.startsWith(`/${URL_PREFIX}/account/history`)) {
            return <AmastyMyReturnsPage { ...this.props } />;
        }

        if (route.startsWith(`/${URL_PREFIX}/account/view/request`)) {
            return <AmastyViewReturn { ...this.props } />;
        }

        if (route.startsWith(`/${URL_PREFIX}/account/newreturn/order`)) {
            return <AmastyNewReturn { ...this.props } />;
        }

        return null;
    }
}

export default MyReturnsTabComponent;

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addAmastyReturnInfoToProductInterfaceFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'amrma_return_info'
];

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: addAmastyReturnInfoToProductInterfaceFields
        }
    }
};

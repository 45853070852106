import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.style';

/** @namespace Frontend/Component/Loader/Component */
export class Loader extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
                    <path d="M22.5,45A22.506,22.506,0,0,1,13.742,1.768,22.506,22.506,0,0,1,31.258,43.232,22.359,22.359,0,0,1,22.5,45ZM7.035,23.387a15.491,15.491,0,0,0,30.929,0h-3.09a2.873,2.873,0,0,1-2.085-.9,3.2,3.2,0,0,1-.876-2.232v-2.2a1.2,1.2,0,0,0-.338-.813,1.159,1.159,0,0,0-1.7,0,1.2,1.2,0,0,0-.338.813v8.715a3.193,3.193,0,0,1-.876,2.231,2.866,2.866,0,0,1-4.168,0,3.193,3.193,0,0,1-.876-2.231V18.059a1.2,1.2,0,0,0-.338-.813,1.159,1.159,0,0,0-1.7,0,1.2,1.2,0,0,0-.338.813v8.715a3.193,3.193,0,0,1-.876,2.231,2.867,2.867,0,0,1-4.169,0,3.192,3.192,0,0,1-.876-2.231V18.059a1.2,1.2,0,0,0-.338-.813,1.16,1.16,0,0,0-1.7,0,1.2,1.2,0,0,0-.338.813v2.2a3.2,3.2,0,0,1-.876,2.232,2.872,2.872,0,0,1-2.084.9Zm15.4-8.291A2.987,2.987,0,0,1,25.4,18.232v8.714a1.161,1.161,0,0,0,.369.844,1.145,1.145,0,0,0,1.625,0,1.161,1.161,0,0,0,.369-.844V18.232a2.97,2.97,0,1,1,5.931,0v2.2a1.16,1.16,0,0,0,.369.844,1.2,1.2,0,0,0,.813.339h3.091a15.491,15.491,0,0,0-30.93,0H9.988a1.2,1.2,0,0,0,.813-.339,1.161,1.161,0,0,0,.369-.844v-2.2a2.97,2.97,0,1,1,5.931,0v8.714a1.16,1.16,0,0,0,.37.844,1.145,1.145,0,0,0,1.626,0,1.161,1.161,0,0,0,.369-.844V18.232A2.987,2.987,0,0,1,22.431,15.1Z" transform="translate(0.5 0.5)" />
                </svg>
            </div>
        );
    }
}

export default Loader;

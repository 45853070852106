/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Forel/AmastyRma/Util/Utils/convertToObjectFromEntries */
export const convertToObjectFromEntries = (entries) => entries.reduce((acc, [k, v]) => {
    acc[k] = v;
    return acc;
}, {});

/** @namespace Forel/AmastyRma/Util/Utils/filterEmptyKeys */
export const filterEmptyKeys = (obj) => Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
        (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? filterEmptyKeys(v) : v }),
        {}
    );

/** @namespace Forel/AmastyRma/Util/Utils/stripHtml */
export const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    const text = tmp.textContent || tmp.innerText || '';
    tmp.remove();

    return text;
};

/** @namespace Forel/AmastyRma/Util/Utils/getDetailsId */
export const getDetailsId = (id) => `amrma-show-more-${id}`;

/** @namespace Forel/AmastyRma/Util/Utils/getQtyOptions */
export const getQtyOptions = (qty) => Array.from({ length: qty }, (_, i) => ({ label: (i + 1), value: (i + 1) }));

/** @namespace Forel/AmastyRma/Util/Utils/getUrlPath */
export const getUrlPath = (url) => (new URL(url)).pathname;

/** @namespace Forel/AmastyRma/Util/Utils/getOrderItemId */
export const getOrderItemId = (i) => `amrma-checkbox-${i}`;

/** @namespace Forel/AmastyRma/Util/Utils/getOrderOption */
export const getOrderOption = ({ label, order_id }) => ({ label, value: order_id });

/** @namespace Forel/AmastyRma/Util/Utils/getRemainingFiles */
export const getRemainingFiles = ({ files, filehash }) => (
    files.filter(({ filehash: currentFileHash }) => (filehash !== currentFileHash))
);

/** @namespace Forel/AmastyRma/Util/Utils/removeEmptySlots */
export const removeEmptySlots = (array) => array.filter((slot) => (!!slot));

/** @namespace Forel/AmastyRma/Util/Utils/getArrayFromRefs */
export const getArrayFromRefs = (refsObj) => Object.keys(refsObj).map(
    (key) => ({ key, value: refsObj[key].current.value })
);

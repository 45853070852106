/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';

import './ReturnsCollapse.style';

/** @namespace Forel/AmastyRma/Component/ReturnsCollapse/Component */
export class ReturnsCollapseComponent extends PureComponent {
    static propTypes = {
        isCollapsed: PropTypes.bool.isRequired,
        toggleCollapse: PropTypes.func.isRequired
    };

    renderCollapse() {
        const { isCollapsed, toggleCollapse } = this.props;
        const testo = isCollapsed ? <CmsBlock identifier="istruzioni-reso" blockType="resi" /> : null;

        return (
            <>
              <div block="ReturnsCollapse" elem="Button">
                    <button onClick={ toggleCollapse }>
                        <div block="ReturnsCollapse" elem="Header">
                            <h4>{ __('Return instructions') }</h4>
                            <div block="ReturnsCollapse" elem="Icon" mods={ { isCollapsed } }>
                                <p>Icona</p>
                            </div>
                        </div>
                    </button>
              </div>
                { testo }
            </>
        );
    }

    render() {
        return (
            <div block="ReturnsCollapse">
                { this.renderCollapse() }
            </div>
        );
    }
}

export default ReturnsCollapseComponent;

/** override sidea */

import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.style';

/**
* @namespace Frontend/Component/FieldSelect/Component
*/
export class FieldSelect extends SourceFieldSelect {
    renderSortIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14.844" height="21.638" viewBox="0 0 14.844 21.638">
                <g transform="translate(14.844) rotate(90)">
                    <g transform="translate(15.367 7.366)">
                        <path d="M3562.771,232.05l-.977-.977,2.762-2.762-2.762-2.762.977-.977,3.739,3.739Z" transform="translate(-3561.795 -224.572)" />
                    </g>
                    <g transform="translate(1.555)">
                        <path d="M3453.034,172.05l-3.739-3.739,3.739-3.739.977.977-2.762,2.762,2.762,2.762Z" transform="translate(-3449.295 -164.572)" />
                    </g>
                    <g transform="translate(0 10.414)">
                        <rect width="10.589" height="1.381" />
                    </g>
                    <g transform="translate(9.898 10.414)">
                        <rect width="9.208" height="1.381" />
                    </g>
                    <g transform="translate(11.049 3.048)">
                        <rect width="10.589" height="1.381" />
                    </g>
                    <g transform="translate(2.532 3.048)">
                        <rect width="9.208" height="1.381" />
                    </g>
                </g>
            </svg>
        );
    }

    renderSortSelect() {
        const { isSortSelect } = this.props;

        const isMobileDevice = window.innerWidth <= 600;

        if (!isSortSelect) {
            return null;
        }

        if (isSortSelect && isMobileDevice) {
            return <div block="FieldSelect" elem="SortSelect">{ __('Sort') } { this.renderSortIcon() }</div>;
        }

        return (
            <div block="FieldSelect" elem="SortSelect">{ __('Sort by') }</div>
        );
    }
}

export default FieldSelect;

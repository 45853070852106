/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

/** @namespace Forel/MageplazaSocialLogin/Component/MyAccountLoader/Component */
export class MyAccountLoaderComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    render() {
        const { isLoading } = this.props;

        return (
            <Loader isLoading={ isLoading } />
        );
    }
}

export default MyAccountLoaderComponent;

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const INCREMENT_ID_LENGTH = 9;

export const NO_RETURNABLE_REASON_ALREADY_RETURNED = 0;
export const NO_RETURNABLE_REASON_EXPIRED_PERIOD = 1;
export const NO_RETURNABLE_REASON_REFUNDED = 2;
export const NO_RETURNABLE_REASON_ITEM_WASNT_SHIPPED = 3;
export const NO_RETURNABLE_REASON_ITEM_WAS_ON_SALE = 4;

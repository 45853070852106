import {
    StoreInPickUpQuery as SourceStoreInPickUpQuery
} from 'SourceQuery/StoreInPickUp.query';
import { Field } from 'Util/Query';

/** @namespace Frontend/Query/StoreInPickUp/Query */
export class StoreInPickUpQuery extends SourceStoreInPickUpQuery {
    getStoreFields() {
        return new Field('stores')
            .addFieldList([
                'city',
                'country',
                'description',
                'name',
                'phone',
                'pickup_location_code',
                'postcode',
                'region',
                'street',
                'is_express'
            ]);
    }
}

export default new StoreInPickUpQuery();

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AmRmaReturnByIdStatusType, CustomerType } from '../../type/AmastyRMA.type';

import './AmastyCustomerInfo.style';

/** @namespace Forel/AmastyRma/Component/AmastyCustomerInfo/Component */
export class AmastyCustomerInfoComponent extends PureComponent {
    static defaultProps = {
        returnCreatedAt: '',
        orderIncrementId: '',
        status: {}
    };

    static propTypes = {
        customer: CustomerType.isRequired,
        returnCreatedAt: PropTypes.string,
        isNewReturn: PropTypes.bool.isRequired,
        orderIncrementId: PropTypes.string,
        status: AmRmaReturnByIdStatusType
    };

    renderOrderIdBlock() {
        const {
            customer: {
                orders: {
                    items: [
                        {
                            increment_id
                        }
                    ]
                }
            },
            orderIncrementId
        } = this.props;

        const idordine = increment_id || orderIncrementId;

        return (
            <div block="AmastyCustomerInfo" elem="ContainerInfo">
                <div className="First">
                    <span>
                        { __('Order ') }
                    </span>
                </div>
                <div className="Second">
                    <span>
                        #
                        { idordine }
                    </span>
                </div>
            </div>
        );
    }

    renderCreatedAtBlock() {
        const {
            isNewReturn,
            returnCreatedAt
        } = this.props;

        if (isNewReturn) {
            return null;
        }

        return (
            <div block="AmastyCustomerInfo" elem="ContainerInfo">
                <div className="First">
                    <span>{ __('Return Created At ') }</span>
                </div>
                <div className="Second">
                    <span>{ returnCreatedAt }</span>
                </div>
            </div>
        );
    }

    renderStatus() {
        const {
            status: {
                color: statusColor,
                label: statusLabel
            }
        } = this.props;

        if (!statusLabel) {
            return null;
        }

        return (
            <div block="AmastyCustomerInfo" elem="ContainerInfo">
                <div className="First">
                    <span>{ __('Status ') }</span>
                </div>
                <div className="Second">
                    <span className="amrma-status" style={ { backgroundColor: statusColor } }>{ __(statusLabel) }</span>
                </div>
            </div>
        );
    }

    render() {
        const {
            customer: {
                firstname: customerFirstName,
                lastname: customerLastName,
                email: customer_email,
                orders: {
                    items: [
                        {
                            billing_address: {
                                firstname: orderFirstName,
                                lastname: orderLastName,
                                street,
                                city,
                                region,
                                postcode,
                                telephone
                            }
                        }
                    ]
                }
            }
        } = this.props;

        return (
            <div block="AmastyCustomerInfo">
                <div className="amrma-customer-information">
                    <div className="amrma-customer-name">
                        <h4 block="AmastyCustomerInfo" elem="InfoTitle">{ __('Request information:') }</h4>
                        { this.renderStatus() }
                        { this.renderOrderIdBlock() }
                        <div block="AmastyCustomerInfo" elem="InfoCustomer">
                            <div block="AmastyCustomerInfo" elem="ContainerInfo">
                                <div className="First">
                                    <span>{ __('Customer name ') }</span>
                                </div>
                                <div className="Second">
                                    <span>{ `${customerFirstName} ${customerLastName}` }</span>
                                </div>
                            </div>
                            <div block="AmastyCustomerInfo" elem="ContainerInfo">
                                <div className="First">
                                    <span>{ __('Email ') }</span>
                                </div>
                                <div className="Second">
                                    <span>{ customer_email }</span>
                                </div>
                            </div>
                            { this.renderCreatedAtBlock() }
                        </div>
                    </div>
                    <div block="AmastyCustomerInfo" elem="AddressInfo">
                        <h4 block="AmastyCustomerInfo" elem="InfoTitle">
                            { __('Contact info:') }
                        </h4>
                        <p>
                            { `${orderFirstName} ${orderLastName}` }
                            <br />
                            { street }
                            <br />
                            { `${postcode} ${city} (${region})` }
                            <br />
                            T:
                            { ' ' }
                            { /* eslint-disable-next-line react/forbid-elements */ }
                            <a href={ `tel:${telephone}` }>{ telephone }</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AmastyCustomerInfoComponent;

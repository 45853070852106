/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { OptionsType, StringOrNumberType } from '../../type/AmastyRMA.type';
import AmastyReturnOption from './AmastyReturnOption.component';

/** @namespace Forel/AmastyRma/Component/AmastyReturnOption/Container */
export class AmastyReturnOptionContainer extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        optionType: PropTypes.string.isRequired,
        orderItemIndex: PropTypes.number.isRequired,
        defaultValue: StringOrNumberType.isRequired,
        options: OptionsType.isRequired,
        amrmaOrderItem: PropTypes.arrayOf.isRequired,
        keyForSelect: PropTypes.number.isRequired
    };

    containerFunctions = {
        changeOptionState: this.changeOptionState.bind(this)
    };

    changeOptionState(value) {
        const {
            onChange,
            optionType,
            orderItemIndex,
            amrmaOrderItem
        } = this.props;

        const key = amrmaOrderItem.map((item) => `key-${item.order_item_id}`);

        onChange(value, optionType, orderItemIndex, key);
    }

    containerProps() {
        const {
            defaultValue,
            options,
            keyForSelect
        } = this.props;

        return {
            defaultValue,
            options,
            keyForSelect
        };
    }

    render() {
        return (
            <AmastyReturnOption
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyReturnOptionContainer;
